import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import SearchSelect from "src/modules/common/components/SearchSelect";
import { areaActions } from "src/modules/estructura/modules/area/handlers/redux";
import { kpiActions } from "../handlers/redux";

function AreaColaboradorSelect() {
  const dispatch = useDispatch();
  const params = useParams();
  const areas_results = useSelector((state) => state.area.areas);
  const { results: areas } = areas_results;
  const areas_filter = useSelector((state) => state.area.areas_filter);
  const { nombre } = areas_filter;
  const filterAreas = (nombre) => {
    dispatch(areaActions.setAreasFilter({ nombre: nombre }));
  };
  //
  const colaboradores_filter = useSelector(
    (state) => state.kpi.colaboradores_filter
  );
  const onSelectItem = (area) => {
    dispatch(
      kpiActions.setColaboradoresFilter({
        ...colaboradores_filter,
        area_id: area,
      })
    );
  };
  //
  return (
    <SearchSelect
      data={areas}
      defaultText={"Áreas: Todas"}
      searchValue={nombre}
      filterFunction={filterAreas}
      onSelectItem={onSelectItem}
    ></SearchSelect>
  );
}
export default AreaColaboradorSelect;
