import { createSlice } from "@reduxjs/toolkit";

const objetivoSlice = createSlice({
  name: "objetivo",
  initialState: {
    colaboradores: {},
    colaboradores_loading: false,
    colaboradores_filter: {},
    colaboradores_count: 0,
    colaboradores_checked: [],
    //
    colaboradorObjetivos: { results: [] },
    colaboradorObjetivos_filter: {},
    colaboradorObjetivos_loading: false,
    colaboradorObjetivos_delete: [],
    colaboradorObjetivos_checked: [],
    //
    colaboradorObjetivosNoVigentes: [],
    colaboradorObjetivosNoVigentes_checked: [],
    colaboradorObjetivosNoVigentes_count: 0,
    colaboradorObjetivosNoVigentes_filter: {},
    colaboradorObjetivosNoVigentes_loading: false,
    //
    objetivo_cargo: {},
    objetivo_cargo_loading: false,
    objetivo_cargo_historial: [],
    objetivo_cargo_historial_loading: false,
    //
    asignar_objetivos_cargo_masivo_loading: false,
    //
    metricas: [],
    periodos: [],
    metricas_loading: false,
    periodos_loading: false,
    //
    fileToUploadPorColaborador: null,
    fileToUploadPorCargo: null,
    importar_loading: false,
    errores_importacion: [],
  },
  reducers: {
    getColaboradores: (state, { payload = {} }) => {
      state.colaboradores_loading = true;
    },
    setColaboradores: (state, { payload = {} }) => {
      state.colaboradores = payload;
    },
    setColaboradoresFilter: (state, { payload = {} }) => {
      state.colaboradores_filter = payload;
    },
    // NO VIGENTES
    getColaboradorObjetivosNoVigentes: (state, { payload }) => {
      state.colaboradorObjetivosNoVigentes_loading = true;
    },
    setColaboradorObjetivosNoVigentesFilter: (state, { payload }) => {
      state.colaboradorObjetivosNoVigentes_filter = payload;
    },
    setColaboradorObjetivosNoVigentes: (state, { payload }) => {
      state.colaboradorObjetivosNoVigentes = payload.results;
      state.colaboradorObjetivosNoVigentes_count = payload.count;
      state.colaboradorObjetivosNoVigentes_loading = false;
    },
    setColaboradorObjetivosNoVigentesSimple: (state, { payload }) => {
      const { results, count } = payload;
      state.colaboradorObjetivosNoVigentes = results;
      state.colaboradorObjetivosNoVigentes_count = count;
    },
    resetCheckObjetivoNoVigente: (state) => {
      state.colaboradorObjetivosNoVigentes_checked = [];
    },
    setObjetivoNoVigentesChecked: (state, { payload }) => {
      state.colaboradorObjetivosNoVigentes_checked = payload;
    },
    checkObjetivoNoVigente: (
      state,
      { payload: { objetivo, checked = true } }
    ) => {
      state.colaboradorObjetivosNoVigentes_checked =
        state.colaboradorObjetivosNoVigentes_checked.filter(
          (objetivonv) => objetivonv.id !== objetivo.id
        );
      if (!!checked) {
        state.colaboradorObjetivosNoVigentes_checked.push(objetivo);
      }
    },
    //
    setObjetivosComplete: (state, { payload = {} }) => {
      state.colaboradores_loading = false;
    },
    setColaboradoresCount: (state, { payload = {} }) => {
      state.colaboradores_count = payload;
    },
    setCheckColaboradores: (state, { payload = {} }) => {
      state.colaboradores_checked = payload;
    },
    setCheckColaborador: (
      state,
      { payload: { colaborador, checked = true } }
    ) => {
      state.colaboradores_checked = state.colaboradores_checked.filter(
        (colaboradorAux) => colaboradorAux.id !== colaborador.id
      );
      if (checked) {
        state.colaboradores_checked.push(colaborador);
      }
    },
    getColaboradorObjetivos: (state, { payload = {} }) => {
      state.colaboradorObjetivos_loading = true;
    },
    setColaboradorObjetivos: (state, { payload = {} }) => {
      state.colaboradorObjetivos = payload.objetivos;
      state.colaboradorObjetivos_loading = false;
    },
    setColaboradorObjetivosFilter: (state, { payload = {} }) => {
      state.colaboradorObjetivos_filter = payload;
    },
    colaboradorUpdateObjetivos: (state, { payload = {} }) => {
      state.colaboradorObjetivos_loading = true;
    },
    colaboradorUpdateObjetivosComplete: (state, { payload = {} }) => {
      state.colaboradorObjetivos_loading = false;
    },
    //
    getObjetivoCargoHistorial: (state, { payload }) => {
      state.objetivo_cargo_historial_loading = true;
    },
    setObjetivoCargoHistorial: (state, { payload }) => {
      state.objetivo_cargo_historial = payload;
      state.objetivo_cargo_historial_loading = false;
    },
    //
    asignarObjetivosCargoMasivo: (state, { payload }) => {
      state.asignar_objetivos_cargo_masivo_loading = true;
    },
    asignarObjetivosCargoMasivoComplete: (state, { payload }) => {
      state.asignar_objetivos_cargo_masivo_loading = false;
    },
    setMetricas: (state, { payload }) => {
      state.metricas = payload.metricas;
      state.metricas_loading = false;
    },
    setPeriodos: (state, { payload }) => {
      state.periodos = payload.periodos;
      state.periodos_loading = false;
    },
    //
    getMetricas: (state, { payload }) => {
      state.metricas_loading = true;
    },
    getPeriodos: (state, { payload }) => {
      state.periodos_loading = true;
    },
    //
    setFileToUploadPorColaborador: (state, { payload = {} }) => {
      state.fileToUploadPorColaborador = payload;
      state.importar_loading = true;
    },
    setFileToUploadPorColaboradorFinal: (state, { payload = {} }) => {
      state.fileToUploadPorColaborador = null;
      state.importar_loading = false;
    },
    setFileToUploadPorCargo: (state, { payload = {} }) => {
      state.fileToUploadPorCargo = payload;
      state.importar_loading = true;
    },
    setFileToUploadPorCargoFinal: (state, { payload = {} }) => {
      state.fileToUploadPorCargo = null;
      state.importar_loading = false;
    },
    setErroresImportacion: (state, { payload = {} }) => {
      state.errores_importacion = payload;
      state.importar_loading = false;
    },
  },
});

export const objetivoActions = objetivoSlice.actions;
export const objetivoReducer = objetivoSlice.reducer;
