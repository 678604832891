import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import DataTable from "src/modules/common/components/DataTable";
import MostrarPaginas from "src/modules/common/components/MostrarPaginas";
import { commonActions } from "src/modules/common/handler/redux";
import { objetivoActions } from "src/modules/objetivos/handlers/redux";
import SearchInput from "src/modules/remuneracion/components/SearchInput";

export function ColaboradorObjetivosPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { idColaborador } = params;

  const colaboradorObjetivosLoading = useSelector(
    (state) => state.objetivo.colaboradorObjetivos_loading
  );
  const objetivos = useSelector((state) => state.objetivo.colaboradorObjetivos);
  const metricas = useSelector((state) => state.objetivo?.metricas);
  const periodos = useSelector((state) => state.objetivo?.periodos);

  const goToObjetivos = () => {
    navigate(`/objetivos/cargos/${idColaborador}`);
  };

  useEffect(() => {
    dispatch(
      commonActions.setEditDetailButton({
        label: "Agregar objetivo",
        action: goToObjetivos,
      })
    );
    if (idColaborador) {
      let item = { id: idColaborador };
      dispatch(objetivoActions.getColaboradorObjetivos(item));
      dispatch(objetivoActions.getMetricas());
      dispatch(objetivoActions.getPeriodos());
    }
  }, []);

  // HISTORICO
  const [PageSize, setPageSize] = useState(10);
  const objetivosNoVigentes = useSelector(
    (state) => state.objetivo.colaboradorObjetivosNoVigentes
  );
  const objetivosNoVigentes_count = useSelector(
    (state) => state.objetivo.colaboradorObjetivosNoVigentes_count
  );
  const objetivosNoVigentes_loading = useSelector(
    (state) => state.objetivo.colaboradorObjetivosNoVigentes_loading
  );
  const objetivosNoVigentes_filter = useSelector(
    (state) => state.objetivo.colaboradorObjetivosNoVigentes_filter
  );
  const datatableConfig = {
    headers: [
      {
        title: "Lista de OBJETIVOs",
        contentClass: "px-2 py-4",
        accessor: "nombre",
      },
      {
        title: "Métrica",
        accessor: "metrica_display",
      },
      {
        title: "Frecuencia",
        accessor: "periodo_display",
      },
      {
        title: "Creado por",
        accessor: "creado_por.nombre",
        contentRenderer: (item) => (
          <div>
            {!!item.creado_por
              ? `${item.creado_por.nombre} ${item.creado_por.apellido}`
              : "Sin registro"}
          </div>
        ),
      },
    ],
    rowActions: [],
  };
  const onPaginate = (page, pageSize) => {
    //
    dispatch(
      objetivoActions.setColaboradorObjetivosNoVigentesFilter({
        ...objetivosNoVigentes_filter,
        id: idColaborador,
        pagination: {
          page: page,
          pageSize: pageSize,
        },
      })
    );
  };
  const onSearchObjetivoNoVigente = (event) => {
    dispatch(
      objetivoActions.setColaboradorObjetivosNoVigentesFilter({
        ...objetivosNoVigentes_filter,
        id: idColaborador,
        nombre: event.target.value,
      })
    );
  };
  const onChangePageSize = (e) => {
    let value = e.target.value;
    //
    dispatch(
      objetivoActions.setColaboradorObjetivosNoVigentesFilter({
        ...objetivosNoVigentes_filter,
        id: idColaborador,
        pagination: {
          pageSize: parseInt(value),
          page: 1,
        },
      })
    );
    //
    // setOuterPageSize(parseInt(value));
  };

  const getPeriodoDisplay = (objetivo) => {
    let periodoDisplay = "-";
    let periodosFilter = periodos.filter(
      (periodo, idx) => periodo.value === objetivo.periodo
    );
    if (periodosFilter.length) periodoDisplay = periodosFilter[0].label;
    return periodoDisplay;
  };
  const getMetricaDisplay = (objetivo) => {
    let metricaDisplay = "-";
    let metricasFilter = metricas.filter(
      (metrica, idx) => metrica.value === objetivo.metrica
    );
    if (metricasFilter.length) metricaDisplay = metricasFilter[0].label;
    return metricaDisplay;
  };
  const getMeta = (objetivo) => {
    const { meta, rangos, metrica } = objetivo;
    if (metrica === 3) {
      //simple
      return "Logo/No Logro";
    }
    if (metrica === 4) {
      //observacion
      return "Observación";
    }
    if (rangos.length > 0) {
      return "Por escala";
    }
    if (meta) {
      return meta.toLocaleString("de-DE");
    }
    return "Sin definir";
  };

  const ObjetivoCardItem = ({ objetivo }) => {
    const { nombre, descripcion } = objetivo;
    return (
      <div className="flex flex-col gap-3 p-4 border border-zinc-300 rounded">
        {/* title */}
        <div className="flex flex-col gap-1">
          <div className="text-sm  font-bold">{nombre}</div>
          <div className="text-sm">{descripcion}</div>
        </div>
        {/* propiedades */}
        <div className="grid grid-cols-2  gap-3">
          {/* prop item */}
          <div className="">
            <div className="text-xs">Tiempo:</div>
            <div className="text-sm font-semibold">
              {getPeriodoDisplay(objetivo)}
            </div>
          </div>
          <div className="">
            <div className="text-xs">Tipo de metrica:</div>
            <div className="text-sm font-semibold">
              {getMetricaDisplay(objetivo)}
            </div>
          </div>
          <div className="">
            <div className="text-xs">Meta:</div>
            <div className="flex flex-row gap-2 items-center">
              <div className="text-sm font-semibold">{getMeta(objetivo)}</div>
            </div>
          </div>
          {/*  */}
          <div className="">
            <div className="text-xs">Creado por:</div>
            <div className="flex flex-row gap-1 items-center">
              {!!objetivo.creado_por && (
                <>
                  <img
                    className="object-cover h-8 w-8 rounded-md"
                    src={objetivo.creado_por?.foto_empleado}
                    alt={`Foto Jefe de Lider`}
                  />
                  <div className="text-sm font-semibold">
                    {`${objetivo.creado_por?.nombre} ${objetivo.creado_por?.apellido}`}
                  </div>
                </>
              )}
              {!objetivo.creado_por && (
                <>
                  <div className="w-8 h-8 rounded bg-slate-300"></div>
                  <div className="text-sm font-semibold">Admin</div>
                </>
              )}
            </div>
          </div>

          {/*  */}
        </div>
      </div>
    );
  };

  return (
    <div className="flex justify-center items-center w-full mt-10 mb-4">
      <div className="flex flex-col w-full md:w-5/6 gap-3">
        <div className="border-b pb-2">
          <span className="font-bold text-lg">
            Objetivos por cargo del colaborador
          </span>
        </div>
        {/* lista */}
        <div className="grid grid-cols-2 gap-3">
          {/* items */}
          {!colaboradorObjetivosLoading &&
            objetivos?.results?.length > 0 &&
            objetivos?.results?.map((objetivo, kIdx) => (
              <ObjetivoCardItem objetivo={objetivo}></ObjetivoCardItem>
            ))}
          {!colaboradorObjetivosLoading && objetivos?.results?.length < 1 && (
            <div className="col-span-2 flex items-center justify-center w-full mt-8">
              <div className="p-2 rounded-md border">
                No existen objetivos por cargo para mostrar
              </div>
            </div>
          )}
          {!!colaboradorObjetivosLoading &&
            [1, 2, 3, 4].map((l, i) => (
              <div
                key={`loading-${i}`}
                className="col-span-1 bg-slate-200 animate-pulse h-52 w-full rounded-md"
              ></div>
            ))}
        </div>
        {/* HISTORICO */}
        <div className="grid grid-cols-12">
          <div className="col-span-1 md:col-span-12 border-b mb-6 mt-4 pb-2">
            <div className="font-bold text-lg">Historial de Objetivos</div>
          </div>
          <div className="col-span-1 md:col-span-6 mb-4 flex gap-4">
            <SearchInput
              placeholder="Buscar objetivo..."
              value={objetivosNoVigentes_filter.nombre}
              onChange={onSearchObjetivoNoVigente}
            />
            <MostrarPaginas
              containerClass={"w-1/2x"}
              onChangePageSize={onChangePageSize}
            />
          </div>
          <div className="col-span-12 border rounded-md bg-white p-2">
            <DataTable
              containerClass={"my-8x"}
              data={objetivosNoVigentes}
              loading={objetivosNoVigentes_loading}
              config={datatableConfig}
              // pagination
              totalRecords={objetivosNoVigentes_count}
              onPaginate={onPaginate}
              outerPageSize={PageSize}
              // check config
              checkable={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
